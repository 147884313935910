import React, { useContext } from "react"
import { AuthContext } from "../../contexts/auth-context"
import { getFirestore, collection, query, where } from "firebase/firestore"
import { useCollection } from "react-firebase-hooks/firestore"
import { Report } from "./report"

export const BillingReports = () => {
  const { partners, app } = useContext(AuthContext)
  const [reports, loading] = useCollection(
    query(
      collection(getFirestore(app), "reports"),
      where(
        "data.partner.id",
        "in",
        partners.map((d) => d.id)
      )
    )
  )

  if (loading) {
    return null
  }

  const report = reports.docs.sort(
    (a, b) => b.data().createdAt - a.data().createdAt
  )[0]

  return (
    <div className="container mx-auto">
      <Report report={report.data().data} id={report.id} />
    </div>
  )
}
