import React, { useContext, useEffect, useState, useCallback } from "react"
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom"
import { AuthContext } from "./contexts/auth-context"
import { Dashboard } from "./features/dashboard"
import { ShowSummary } from "./features/billing-reports/show-summary"
import { Login as LoginLib, Helpers } from "@flexday/common-react"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { useAuthState } from "react-firebase-hooks/auth"

window.gfs2 = getFirestore
window.React2 = React

if (window.gfs1 !== window.gfs2) {
  console.error("Firebase mismatch", {
    gfs1: window.gfs1,
    gfs2: window.gfs2,
    eq: window.gfs1 === window.gfs2,
  })
}

if (window.React1 !== window.React2) {
  console.error("React mismatch", {
    r1: window.React1,
    r2: window.React2,
    eq: window.React1 === window.React2,
  })
}

const { fetchPartnersForUser, getFirebaseConfig } = Helpers
const { EmailSignIn, EmailLogin, Login } = LoginLib

const firebaseConfig = getFirebaseConfig(process.env)

const app = initializeApp(firebaseConfig)

function App() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  )
}

function AppWithRouter() {
  const auth = getAuth(app)
  const [authUser, authUserLoading] = useAuthState(auth)
  const [partners, setPartners] = useState([])
  const [loadingPartners, setLoadingPartners] = useState(false)

  const history = useHistory()

  const ensurePartner = useCallback(async (uid) => {
    setLoadingPartners(true)

    if (!uid) {
      await setPartners([])
    } else {
      await setPartners((await fetchPartnersForUser(uid, app)).docs)
    }

    await setLoadingPartners(false)
  }, [])

  const onLoginSuccess = async () => {
    await ensurePartner(auth.currentUser.uid)

    history.push("/")
  }

  const onLoginError = (error) => {
    console.error({ loginError: error })
  }

  const onEmailSignInClick = () => {
    history.push("/emailLogin")
  }

  useEffect(() => {
    if (authUserLoading) {
      return null
    }

    ensurePartner(authUser?.uid)
  }, [authUser, authUserLoading, ensurePartner])

  if (loadingPartners) {
    return null
  }

  console.debug("rendering AppWithRouter", {
    authUser,
    partners,
    loadingPartners,
  })

  return (
    <Switch>
      <Route path="/signIn">
        <EmailSignIn
          app={app}
          onSuccess={onLoginSuccess}
          onError={onLoginError}
        />
      </Route>

      <Route path="/login">
        <Login
          logo={<Logo />}
          app={app}
          onSuccess={onLoginSuccess}
          onError={onLoginError}
          onEmailSignInClick={onEmailSignInClick}
        />
      </Route>

      <Route path="/emailLogin">
        <EmailLogin
          app={app}
          logo={<Logo />}
          signInUrl={`${process.env.REACT_APP_APP_URL}/signIn`}
        />
      </Route>

      <Route path="/summaries/:id/:slug">
        <ShowSummary app={app} />
      </Route>

      <AuthContext.Provider
        value={{ app, authUser, partners, loading: loadingPartners }}>
        <PrivateRoute path="/">
          <Dashboard />
        </PrivateRoute>
      </AuthContext.Provider>
    </Switch>
  )
}

function Logo() {
  return (
    <img
      src="/logo-black.png"
      className="m-auto scale-50 filter dark:invert"
      alt="Flexday logo"
    />
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const { partners, loading, authUser } = useContext(AuthContext)

  if (loading) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!authUser) {
          return (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        } else if (partners.length < 1) {
          return <PartnersOnly />
        } else {
          return children
        }
      }}
    />
  )
}

const PartnersOnly = () => (
  <Router>
    <div className="m-2 text-lw">
      <strong>Oops!</strong> We can't seem to find any spaces associated to your
      account. Log out of your{" "}
      <a
        className="font-bold cursor-pointer bg-black text-white px-2 py-1"
        href="https://myaccount.google.com/">
        Google account
      </a>{" "}
      and try again.
    </div>
  </Router>
)

export default App
