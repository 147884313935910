import React from "react"
import { doc, getFirestore } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { PartnerBilling } from "@flexday/common-react"
import { Link, useParams } from "react-router-dom"

export const ShowSummary = ({ app }) => {
  const { id } = useParams()
  const [report, loading, error] = useDocumentData(
    doc(getFirestore(app), "reports", id)
  )

  console.log({ report, id, loading, error })

  if (!report) {
    return null
  }

  return (
    <div className="flex flex-col h-screen dark:text-gray-300">
      <Header className="p-5 flex items-start justify-between" />
      <PartnerBilling.Report report={report.data} />
      <Footer />
    </div>
  )
}

const Header = ({ className }) => (
  <div className={className}>
    <div className="flex flex-col sm:flex-row">
      <Link
        to={"/"}
        className="flex flex-col items-center uppercase tracking-widest text-xs text-gray-500 mr-6">
        <Logo className="w-24 inline-block" />
        <span>Partner</span>
      </Link>
    </div>
  </div>
)

const Logo = ({ className }) => (
  <picture className={className}>
    <source srcSet="/logo-white.png" media="(prefers-color-scheme: dark)" />
    <img src="/logo-black.png" alt="Flexday logo" />
  </picture>
)

const Footer = () => {
  return (
    <div className="bg-black dark:border-t-8 dark:border-gray-800 text-gray-200 px-4 pt-10 pb-20 mt-20 h-screen">
      <p>
        Want more insight on your spaces' reservations and check-ins?{" "}
        <Link to="/" className="font-semibold">
          Log in to the Flexday Partner Portal.
        </Link>
      </p>
    </div>
  )
}
