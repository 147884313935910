import React, { useContext } from "react"
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore"
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore"
import { AuthContext } from "../../contexts/auth-context"
import { startOfToday } from "date-fns"
import { Overview as CommonOverview, Errors } from "@flexday/common-react"

export const Overview = () => {
  const { app, partners } = useContext(AuthContext)
  const spacesRef = collection(
    getFirestore(app),
    `partners/${partners[0].id}/spaces`
  )

  const [spaces, loadingSpaces, spacesError] = useCollection(spacesRef)

  const bookingsRef = collection(getFirestore(app), "bookings")

  const spaceIds = (spaces?.docs || [{ id: "SPACES_NOT_LOADED" }]).map(
    (s) => s.id
  )

  const [bookings, loadingBookings, bookingsError] = useCollectionData(
    query(
      bookingsRef,
      where("spaceId", "in", spaceIds),
      where("createdAt", ">", startOfToday().getTime()),
      orderBy("createdAt", "desc")
    )
  )

  const reservationsRef = collection(getFirestore(app), "reservations")

  const [reservations, loadingReservations, reservationsError] =
    useCollectionData(
      query(
        reservationsRef,
        where("spaceId", "in", spaceIds),
        where("scheduledAt", ">", startOfToday().getTime()),
        orderBy("scheduledAt", "desc")
      )
    )

  console.log({ partners })

  const loading = [loadingSpaces, loadingReservations, loadingBookings].some(
    (l) => l
  )

  if (loading) {
    console.debug("loading...", {
      loadingSpaces,
      loadingReservations,
      loadingBookings,
    })
    return null
  }

  const errors = [spacesError, reservationsError, bookingsError].filter(
    (e) => e
  )
  if (errors.length > 0) {
    console.error({ errors })
    return <Errors.Errors errors={errors} />
  }

  return (
    <div className="mx-auto px-4 max-w-screen-lg">
      <CommonOverview.OverviewView
        bookings={bookings}
        reservations={(reservations || []).filter((r) =>
          ["active", "cancelled"].includes(r.status)
        )}
      />
    </div>
  )
}
