import { useContext } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  NavLink,
  Link,
} from "react-router-dom"
import { LogoutIcon as LogOutIcon } from "@heroicons/react/outline"
import { AuthContext } from "../../contexts/auth-context"
import { getAuth, signOut } from "firebase/auth"
import { Overview } from "../overview"
import { BillingReports } from "../billing-reports"

export const Dashboard = () => {
  return (
    <div>
      <Router>
        <Header className="p-5 flex items-start justify-between" />

        <Switch>
          <Route path="/" exact component={Overview} />
          <Route path="/summaries" exact component={BillingReports} />
        </Switch>
      </Router>
    </div>
  )
}

const Header = ({ className }) => (
  <div className={className}>
    <div className="flex flex-col sm:flex-row">
      <Link
        to={"/"}
        className="flex flex-col items-center uppercase tracking-widest text-xs text-gray-500 mr-6">
        <Logo className="w-24 inline-block" />
        <span>Partner</span>
      </Link>
      <Nav />
    </div>
    <LogOut />
  </div>
)

const Nav = () => (
  <nav className="ml-2">
    <ul className="flex flex-col sm:flex-row gap-2 text-gray-400 dark:text-gray-500">
      <li>
        <NavLink
          to="/summaries"
          activeClassName="text-gray-800 dark:text-gray-200">
          Monthly Summaries
        </NavLink>
      </li>
    </ul>
  </nav>
)

const Logo = ({ className }) => (
  <picture className={className}>
    <source srcSet="/logo-white.png" media="(prefers-color-scheme: dark)" />
    <img src="/logo-black.png" alt="Flexday logo" />
  </picture>
)

const LogOut = () => {
  const { app } = useContext(AuthContext)
  const history = useHistory()

  const onClick = async () => {
    console.debug("logging out...")
    await signOut(getAuth(app))
    history.push("/login")
  }

  return (
    <div
      className="p-2 pt-0 pr-0 cursor-pointer text-gray-400"
      onClick={onClick}>
      <LogOutIcon className="h-6 w-6" />
    </div>
  )
}
